<template>
    <Modal v-model="showForm" title="分解目标" width="680" :mask-closable="false">
        <Form ref="editForm" :model="editForm" label-colon :label-width="80">
            <Row :gutter="16">
                <Col span="12">
                    <FormItem label="类型">{{ myGoal.typeName }}</FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="目标周期">{{ myGoal.startDate + ' ~ ' + myGoal.endDate }}</FormItem>
                </Col>
            </Row>
            <Row :gutter="16">
                <Col span="12">
                    <FormItem label="金额">{{ myGoal.amount }}</FormItem>
                </Col>
            </Row>
            <div class="filter">
                <Form ref="filter" label-colon inline>
                    <FormItem>
                        <Input v-model="filter.keywords" placeholder="请输入目标责任人" clearable/>
                    </FormItem>
                    <FormItem>
                        <Button type="primary" @click="handleSearch">搜索</Button>
                        <Button type="primary" @click="handleAddStakeholders">新增</Button>
                    </FormItem>
                </Form>
            </div>
            <Table stripe :columns="columns" :data="copyList">
                <template slot-scope="{ row, index }" slot="action">
                    <a @click="handleEditStakeholders(row, index)">编辑</a>
                    <Divider type="vertical"/>
                    <a @click="handleDelStakeholders(row, index)">删除</a>
                </template>
            </Table>
        </Form>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">确定</Button>
        </div>
        <add-stakeholders v-model="showAddStakeholders" :goal="goal" :stakeholdersList="editForm.stakeholdersList"
                          v-on:closeAddStakeholders="closeAddStakeholders"></add-stakeholders>
        <editStakeholders v-model="showEditStakeholders" :data="data"
                          v-on:closeEditStakeholders="closeEditStakeholders"/>
        <edit-stakeholders v-model="showEditStakeholders" :data="data" :stakeholdersList="editForm.stakeholdersList"
                           v-on:closeEditStakeholders="closeEditStakeholders"></edit-stakeholders>
    </Modal>
</template>
<script>
import addStakeholders from './addStakeholders'
import {reqEditGoal, reqGetGoalByParentId} from "@/api/admin-api";
import editStakeholders from './editStakeholders'

export default {
    name: "editGoal",
    components: {
        addStakeholders,
        editStakeholders
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        goal: null,
    },
    data() {
        return {
            myGoal: {
                typeName: null,
                startDate: null,
                endDate: null
            },
            data: {},
            filter: {
                keywords: null,
            },
            deleteId: [],
            loading: false,
            showForm: this.value,
            showAddStakeholders: false,
            showEditStakeholders: false,
            copyList: [],
            editForm: {
                stakeholdersList: [],
            },
            columns: [
                {
                    title: '目标责任人',
                    key: 'stakeholderName',
                    minWidth: 150,
                },
                {
                    title: '所属组织',
                    key: 'deptName',
                    minWidth: 150,
                },
                {
                    title: '金额(元)',
                    key: 'amount',
                    width: 120,
                },
                {
                    title: '操作',
                    slot: 'action',
                    width: 120,
                    align: 'center',
                },
            ],
        }
    },
    methods: {
        closeAddStakeholders(val) {
            for (let i = 0; i < val.stakeholder.length; i++) {
                this.editForm.stakeholdersList.push({
                    amount: val.amount,
                    department: val.department,
                    deptName: val.departmentName,
                    stakeholder: val.stakeholder[i],
                    stakeholderName: val.stakeholdersName[i],
                    category: val.departmentType
                });
            }
            this.copyList = this.editForm.stakeholdersList
        },
        closeEditStakeholders(val) {
            this.editForm.stakeholdersList.find(item => item.id === val.id).amount = val.amount;
            this.copyList = this.editForm.stakeholdersList
        },
        handleAddStakeholders() {
            this.showAddStakeholders = true
        },
        handleSubmit() {
            this.loading = true
            if (this.editForm.stakeholdersList.length === 0) {
                this.loading = false;
                this.$Message.error('请选择目标责任人');
                return false;
            }

            this.editForm.deleteId = this.deleteId
            this.editForm.id = this.myGoal.id
            reqEditGoal(this.editForm).then((res) => {
                this.loading = false;
                if (res.data.code === 1) {
                    this.$Message.success('编辑成功');
                    this.$emit('getGoal');
                    this.closeModal(false);
                }
            }).catch(() => {
                this.loading = false;
                this.$Message.error('编辑失败');
            })
        },
        handleSearch() {
            if (this.filter.keywords) {
                let myData = JSON.parse(JSON.stringify(this.editForm.stakeholdersList));
                this.copyList = myData.filter(item => (item.stakeholdersName).includes(this.filter.keywords));
            } else {
                this.copyList = JSON.parse(JSON.stringify(this.editForm.stakeholdersList));
            }
        },
        handleDelStakeholders(row, index) {
            if (row.id) {
                this.deleteId.push(row.id)
            }
            this.editForm.stakeholdersList.splice(index, 1);
            this.copyList = this.editForm.stakeholdersList
        },
        handleEditStakeholders(row, index) {
            this.data = JSON.parse(JSON.stringify(row))
            this.showEditStakeholders = true
        },
        getGoalByParentId() {
            reqGetGoalByParentId({id: this.myGoal.id}).then((res) => {
                this.editForm.stakeholdersList = res.data.data;
                this.editForm.type = res.data.data[0].type;
                this.editForm.startDate = new Date(res.data.data[0].startDate)
                this.editForm.endDate = new Date(res.data.data[0].endDate)
                this.copyList = this.editForm.stakeholdersList
            }).catch(() => {
                this.editForm.stakeholdersList = []
                this.copyList = []
            })
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.$refs['editForm'].resetFields();
                this.copyList = []
                this.editForm.stakeholdersList = []
                this.myGoal = this.goal;
                this.editForm.parentDeptId = this.myGoal.deptId
                this.getGoalByParentId()
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    },
    mounted() {
    }
}
</script>
