<template>
    <Modal v-model="showForm" title="变更目标" width="680" :mask-closable="false">
        <Form ref="editForm" label-colon :label-width="90">
            <Row :gutter="16">
                <Col span="12">
                    <FormItem label="类型">{{ myGoal.typeName }}</FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="目标周期">{{ myGoal.startDate + ' ~ ' + myGoal.endDate}}</FormItem>
                </Col>
            </Row>
            <div class="filter">
                <Form ref="filter" label-colon inline>
                    <FormItem>
                        <Input v-model="filter.keywords" placeholder="请输入目标责任人" clearable/>
                    </FormItem>
                    <FormItem>
                        <Button type="primary" @click="handleSearch">搜索</Button>
                        <Button type="primary" @click="handleAddGoal">新增</Button>
                    </FormItem>
                </Form>
            </div>
            <Table stripe :columns="columns" :data="copyOfGoalList">
                <template slot-scope="{ row, index }" slot="action">
                    <a @click="handleEditGoal(row, index)">编辑</a>
                    <Divider type="vertical"/>
                    <a @click="handleDelGoal(row, index)">删除</a>
                </template>
            </Table>
        </Form>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">确定</Button>
        </div>
        <addStakeholders v-model="showAddGoal" :goal="goal" :stakeholdersList="goalList" v-on:closeAddStakeholders="closeAddStakeholders"/>
        <editStakeholders v-model="showEditGoal" :data="selectedGoal" v-on:closeEditStakeholders="closeEditStakeholders"/>
    </Modal>
</template>
<script>
import {reqGetGoalByParentId, reqChangeGoal} from "@/api/admin-api";
import addStakeholders from './addStakeholders'
import editStakeholders from './editStakeholders'

export default {
    name: "changeGoal",
    components: {addStakeholders, editStakeholders},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        goal: null,
    },
    data() {
        return {
            filter: {
                keywords: null,
            },
            loading: false,
            showForm: this.value,
            myGoal: {
                typeName: null,
                startDate: null,
                endDate: null
            },
            selectedGoal: null,
            showAddGoal: false,
            showEditGoal: false,
            goalList: [],
            copyOfGoalList: [],
            columns: [
                {
                    title: '目标责任人',
                    key: 'stakeholderName',
                    minWidth: 150,
                },
                {
                    title: '所属组织',
                    key: 'deptName',
                    minWidth: 150,
                },
                {
                    title: '金额(元)',
                    key: 'amount',
                    width: 150,
                },
                {
                    title: '操作',
                    slot: 'action',
                    width: 120,
                    align: 'center',
                    fixed: 'right',
                },
            ],
        }
    },
    methods: {
        closeAddStakeholders(val) {
            for (let i = 0; i < val.stakeholder.length; i++) {
                this.goalList.push({
                    amount: val.amount,
                    deptId: val.department,
                    deptName: val.departmentName,
                    stakeholder: val.stakeholder[i],
                    stakeholderName: val.stakeholdersName[i],
                    departmentType: val.departmentType
                });
            }
            this.copyOfGoalList = this.goalList;
        },
        closeEditStakeholders(val) {
            this.goalList.find(item => item.id === val.id).amount = val.amount;
            this.copyOfGoalList.find(item => item.id === val.id).amount = val.amount;
        },
        handleAddGoal() {
            this.showAddGoal = true;
        },
        handleSubmit() {
            this.loading = true;
            let params = {
                parentId: this.myGoal.id,
                type: this.myGoal.type,
                startDate: this.myGoal.startDate,
                endDate: this.myGoal.endDate,
                goals: this.goalList
            }
            reqChangeGoal(params).then((res) => {
                this.loading = false;
                if (res.data.data === 1) {
                    this.$Message.success('变更成功');
                    this.$emit('getGoal');
                    this.closeModal(false);
                } else {
                    this.$Message.error('变更失败');
                }
            }).catch(() => {
                this.loading = false;
                this.$Message.error('变更失败');
            })
        },
        handleSearch() {
            let myData = JSON.parse(JSON.stringify(this.goalList));
            if (this.filter.keywords) {
                this.copyOfGoalList = myData.filter(item => (item.stakeholderName).includes(this.filter.keywords));
            } else {
                this.copyOfGoalList = myData;
            }
        },
        handleDelGoal(row, index) {
            this.goalList = this.goalList.filter(item => item.id !== row.id);
            this.copyOfGoalList = this.copyOfGoalList.filter(item => item.id !== row.id);
        },
        handleEditGoal(row, index) {
            this.selectedGoal = JSON.parse(JSON.stringify(row));
            this.showEditGoal = true
        },
        getGoalByParentId() {
            reqGetGoalByParentId({id: this.goal.id}).then((res) => {
                this.goalList = res.data.data;
                this.copyOfGoalList = JSON.parse(JSON.stringify(this.goalList));
            }).catch(() => {
                this.goalList = [];
                this.copyOfGoalList = [];
            })
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.filter.keywords = null;
                this.selectedGoal = null;
                this.goalList = [];
                this.copyOfGoalList = [];
                this.myGoal = this.goal;
                this.getGoalByParentId();
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    },
    mounted() {}
}
</script>
