<template>
    <div class="main-content">
        <div class="breadcrumb">
            <Breadcrumb>
                <BreadcrumbItem>目标管理</BreadcrumbItem>
            </Breadcrumb>
        </div>
        <div class="filter">
            <Form label-colon inline>
                <FormItem>
                    <DatePicker type="daterange" v-model="filter.goalDateRange" placeholder="请选择目标创建周期" separator=" 至 " clearable></DatePicker>
                </FormItem>
                <FormItem>
                    <Select v-model="filter.status" placeholder="请选择状态" clearable>
                        <Option value="待提审">待提审</Option>
                        <Option value="审批中">审批中</Option>
                        <Option value="通过">通过</Option>
                        <Option value="驳回">驳回</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="doSearching">搜索</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="resetting">重置</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" icon="md-add" @click="handleAdd" v-if="permissionButton.add">新增</Button>
                </FormItem>
            </Form>
        </div>
        <div class="table">
            <div class="table-list">
                <Table stripe :columns="columns" :data="dataList" :loading="loading">
                    <template slot-scope="{ row, index }" slot="action">
                        <Dropdown transfer>
                            <a href="javascript:void(0)">操作<Icon type="ios-arrow-down"></Icon></a>
                            <DropdownMenu class="table-dropdown" slot="list">
                                <DropdownItem v-if="permissionButton.view" @click.native="handleView(row, index)">详情</DropdownItem>
                                <DropdownItem v-if="permissionButton.edit && row.category === 0 && (row.status === '待提审' || row.status === '驳回') && row.verifyStatus ==='通过'" @click.native="handleEdit(row, index)">分解下级</DropdownItem>
                                <DropdownItem v-if="permissionButton.submit && row.category === 0 && (row.status === '待提审' || row.status === '驳回') && row.verifyStatus ==='通过'" @click.native="handleSubmit(row, index)">提审</DropdownItem>
                                <DropdownItem v-if="permissionButton.change && row.category === 0 && row.status === '通过'" @click.native="handleChange(row, index)">变更</DropdownItem>
                                <DropdownItem class="table-delete" v-if="permissionButton.del && row.category === 0" @click.native="handleDel(row, index)">删除</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </template>
                </Table>
            </div>
            <div class="table-footer">
                <Row>
                    <Col class="total" span="6">共 {{ total }} 条记录 第 {{ pageNum }}/{{ pages }} 页</Col>
                    <Col class="pagenation" span="18">
                        <Page :page-size="pageSize" :total="total" :current="pageNum"
                              :page-size-opts="[15, 20, 50, 100]"
                              @on-change="changePage" @on-page-size-change="changPageSize" show-sizer show-elevator/>
                    </Col>
                </Row>
            </div>
            <add-goal v-model="showAddModal" v-on:getGoal="getGoal"></add-goal>
            <view-goal v-model="showViewModal" :id="id"></view-goal>
            <edit-goal v-model="showEditModal" :goal="goal" v-on:getGoal="getGoal"></edit-goal>
            <change-goal v-model="showChangeModal" :goal="goal" v-on:getGoal="getGoal"></change-goal>
        </div>
    </div>
</template>

<script>
import permission from '@/common/permission'
import {reqDeleteGoal, reqGetGoal, reqSubmitGoal} from "@/api/admin-api";
import addGoal from './add'
import viewGoal from './view'
import editGoal from './edit'
import changeGoal from './change'
import {reqCheckIfGoalCanSubmit} from "../../api/admin-api";

export default {
    name: 'goalList',
    components: {addGoal,viewGoal,editGoal,changeGoal},
    data() {
        return {
            id: '',
            goal: null,
            filter: {
                status: null,
                goalDateRange: null,
            },
            showAddModal: false,
            showViewModal: false,
            showEditModal: false,
            showChangeModal: false,
            loading: false,
            dataList: [],
            permissionButton: {
                add: false,
                edit: false,
                view: false,
                del: false,
                submit: false,
                change: false,
            },
            total: 0,
            pages: 1,
            pageSize: 20,
            pageNum: 1,
            columns: [
                {
                    title: '类型',
                    key: 'typeName',
                    width: 100
                },
                {
                    title: '目标周期',
                    width: 200,
                    render: (h, params) => {
                        return h(
                            'span',
                            params.row.startDate + ' ~ ' + params.row.endDate
                        )
                    },
                },
                {
                    title: '所属组织',
                    key: 'deptName',
                    minWidth: 260,
                },
                {
                    title: '目标责任人',
                    key: 'stakeholderName',
                    width: 120,
                },
                {
                    title: '金额(元)',
                    width: 120,
                    render: (h, params) => {
                        return h('span', params.row.amount.toFixed(2))
                    }
                },
                {
                    title: '当前达成率',
                    key: 'achievingRate',
                    width: 120,
                    render: (h, params) => {
                        return h('span', params.row.achievingRate ? (params.row.achievingRate + '%') : '')
                    },
                },
                {
                    title: '状态',
                    key: 'status',
                    width: 100,
                },
                {
                    title: '创建人',
                    key: 'createByName',
                    minWidth: 180,
                },
                {
                    title: '更新时间',
                    key: 'updatedTime',
                    width: 160,
                },
                {
                    title: '操作',
                    slot: 'action',
                    width: 120,
                    align: 'center',
                    fixed: 'right',
                },
            ],
        }
    },
    methods: {
        getGoal() {
            this.loading = true;
            this.dataList = [];
            let endTime =  ((this.filter.goalDateRange && this.filter.goalDateRange[1] !== '' && this.filter.goalDateRange.length > 1) ? this.$moment(this.filter.goalDateRange[1]).format('YYYY-MM-DD') : null);
            let startTime = ((this.filter.goalDateRange && this.filter.goalDateRange[0] !== '' && this.filter.goalDateRange.length > 0) ? this.$moment(this.filter.goalDateRange[0]).format('YYYY-MM-DD') : null);
            let para = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                status: this.filter.status,
                startTime: startTime,
                endTime: endTime,
            };
            reqGetGoal(para).then((res)=>{
                this.loading = false;
                this.pageNum = res.data.data.current
                this.pages = res.data.data.pages
                this.total = res.data.data.total
                this.dataList = res.data.data.records
            }).catch(()=>{
                this.loading = false;
                this.dataList = [];
            })
        },
        resetting() {
            this.loading = true;
            this.dataList = [];
            this.filter.status = null;
            this.filter.goalDateRange = null;
            let endTime =  ((this.filter.goalDateRange && this.filter.goalDateRange[1] !== '' && this.filter.goalDateRange.length > 1) ? this.$moment(this.filter.goalDateRange[1]).format('YYYY-MM-DD') : null);
            let startTime = ((this.filter.goalDateRange && this.filter.goalDateRange[0] !== '' && this.filter.goalDateRange.length > 0) ? this.$moment(this.filter.goalDateRange[0]).format('YYYY-MM-DD') : null);
            let para = {
                pageNum: 1,
                pageSize: 20,
                status: this.filter.status,
                startTime: startTime,
                endTime: endTime,
            };
            reqGetGoal(para).then((res)=>{
                this.loading = false;
                this.pageNum = res.data.data.current
                this.pages = res.data.data.pages
                this.total = res.data.data.total
                this.dataList = res.data.data.records
            }).catch(()=>{
                this.loading = false;
                this.dataList = [];
            })
        },
        handleAdd() {
            this.showAddModal = true
        },
        handleEdit(row, index) {
            this.goal = row;
            this.showEditModal = true
        },
        handleSubmit(row, index) {
            reqCheckIfGoalCanSubmit({id: row.id}).then((res) => {
                if (res.data.data) {
                    this.$Modal.confirm({
                        title: '目标提审',
                        content: '确定提审'+row.typeName+'目标<br/>周期：'+row.startDate + ' ~ ' + row.endDate,
                        width: '260',
                        onOk: () => {
                            reqSubmitGoal({id: row.id}).then((res) => {
                                if (res.data.code === 1) {
                                    this.$Message.success('操作成功');
                                    this.getGoal();
                                } else {
                                    this.$Message.error('操作失败');
                                }
                            }).catch(()=>{
                                this.$Message.error('操作失败');
                            })
                        }
                    })
                } else {
                    this.$Message.error('请编辑添加下级目标');
                }
            }).catch(()=>{
                this.$Message.error('操作失败');
            })
        },
        handleChange(row, index) {
            this.goal = row;
            this.showChangeModal = true;
        },
        handleView(row, index) {
            this.id = row.id
            this.showViewModal = true
        },
        handleDel(row, index) {
            this.$Modal.confirm({
                title: '删除目标',
                content: '确定删除目标：' + row.deptName + ',目标金额:' + row.amount + '？此操作不可逆。',
                width: '360',
                onOk: () => {
                    reqDeleteGoal({id:row.id}).then((res)=>{
                        if (res.data.code === 1) {
                            this.getGoal()
                            this.$Message.success('删除成功');
                        } else {
                            this.$Message.error('删除失败');
                        }
                    }).catch(()=>{
                        this.$Message.error('删除失败');
                    })
                }
            })
        },
        //分页点击，切换页面
        changePage(val) {
            this.pageNum = val;
            this.getGoal()
        },
        //每页显示的数据条数
        changPageSize(numOfRows) {
            //实时获取当前需要显示的条数
            this.pageSize = numOfRows;
            this.getGoal()
        },
        doSearching() {
            this.pageNum = 1;
            this.getGoal()
        }
    },
    mounted() {
        let permissionId = this.$route.query.permissionId;
        permission.getPermissionButton(permissionId, this.permissionButton);
        this.getGoal();
    }
}
</script>
