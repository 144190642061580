<template>
    <Modal v-model="showForm" title="新增目标责任人" :mask-closable="false">
        <Form ref="addForm" :model="addForm" :rules="addFormRule" label-colon :label-width="120">

            <FormItem label="所属组织" prop="department">
                <Select v-model="addForm.department" placeholder="请选择" @on-change="changeDepartment" @on-clear="clearDepartment" label-in-value
                        filterable clearable>
                    <Option v-for="item in departArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
            </FormItem>
            <FormItem label="选择目标责任人" prop="stakeholder">
                <Select v-model="addForm.stakeholder" placeholder="请选择" @on-change="changeStakeholders"
                        multiple label-in-value filterable clearable>
                    <Option v-for="item in stakeholdersArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
            </FormItem>
            <FormItem class="plain-item" label="目标金额" prop="amount">
                <Input v-model="addForm.amount" placeholder="请输入"></Input>
            </FormItem>
        </Form>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">确定</Button>
        </div>
    </Modal>
</template>
<script>

import {reqGetDepartmentArray, reqGetStaffStakeholders, reqGetStakeholders} from "@/api/admin-api";

export default {
    name: "addStakeholders",
    components: {},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        stakeholdersList: [],
        goal: null,
    },
    data() {
        return {
            departArray: [],
            stakeholdersArray: [],
            header: {},
            loading: false,
            showForm: this.value,
            addForm: {
                department: null,
                stakeholder: null,
                amount: null,
                stakeholdersName: null,
                departmentName: null,
                departmentType: null,
            },
            addFormRule: {
                department: [
                    {required: true, message: '请选择客户名称', trigger: 'change'}
                ],
                stakeholder: [
                    {required: true, message: '请选择客户名称', trigger: 'change', type:'array'}
                ],
                amount: [
                    {required: true, message: "请输入金额", trigger: "blur"},
                    {
                        pattern: /^(([1-9]{1}\d{0,7})|(0{1}))(\.\d{1,2})?$/,
                        message: "请输入合法的金额数字，最多两位小数，最大金额99999999.99",
                        trigger: "blur"
                    }
                ],
            },
        }
    },
    methods: {
        handleSubmit() {
            this.loading = true
            this.$refs['addForm'].validate((valid) => {
                if (!valid) {
                    this.loading = false;
                    return false;
                }
                if (this.addForm.amount === '0'){
                    this.$Message.error("金额不能为0")
                    this.loading = false;
                    return false;
                }
                this.loading = false;
                this.$emit('closeAddStakeholders', this.addForm);
                this.closeModal(false);
            })
        },
        changeStakeholders(val) {
            this.addForm.stakeholdersName = []
            if (val !== null && val !== undefined) {
                for (let i = 0; i < val.length; i++) {
                    this.addForm.stakeholdersName.push(val[i].label)
                }
            }
        },
        changeDepartment(val) {
            if (val !== null && val !== undefined) {
                this.addForm.stakeholder = null;
                this.addForm.departmentName = val.label
                let userId = ''
                let list = []
                this.stakeholdersArray = []
                if (this.stakeholdersList.length !== 0 ) {
                    for (let j = 0; j < this.stakeholdersList.length; j++) {
                        list.push(this.stakeholdersList[j].stakeholder)
                    }
                }
                for (let i = 0; i < this.departArray.length; i++) {
                    if (this.departArray[i].id === val.value && this.departArray[i].type === "0") {
                        userId = list.join(",")
                        let params = {
                            id: val.value,
                            userId: userId
                        };
                        reqGetStakeholders(params).then((res) => {
                            if (res.data.data.managerId !== null && res.data.data.managerId !== ''){
                                this.stakeholdersArray.push({id: res.data.data.managerId, name: res.data.data.managerName})
                            }
                        }).catch(() => {
                            this.stakeholdersArray = []
                        })
                    } else if (this.departArray[i].id === val.value && this.departArray[i].type === "1") {
                        userId = list.join(",")
                        let params = {
                            id: val.value,
                            userId: userId
                        };
                        reqGetStaffStakeholders(params).then((res) => {
                            for (let j = 0; j < res.data.data.length; j++) {
                                this.stakeholdersArray.push({id: res.data.data[j].userId, name: res.data.data[j].name})
                            }
                        }).catch(() => {
                            this.stakeholdersArray = []
                        })
                    }
                }
            }
        },
        getDepartmentArray() {
            if (this.goal !== undefined && this.goal.parentDeptId){
                reqGetDepartmentArray({parentId: this.goal.parentDeptId}).then((res) => {
                    for (let i = 0; i < res.data.data.length; i++) {
                        if (res.data.data[i].type === '0') {
                            this.departArray.push(res.data.data[i])
                        }
                    }
                    this.addForm.departmentType = 0
                    if (this.departArray.length === 0) {
                        for (let i = 0; i < res.data.data.length; i++) {
                            if (res.data.data[i].type === '1') {
                                this.departArray.push(res.data.data[i])
                            }
                        }
                        this.addForm.departmentType = 1
                    }
                }).catch(() => {
                    this.departArray = []
                })
            }else {
                reqGetDepartmentArray({parentId: ''}).then((res) => {
                    for (let i = 0; i < res.data.data.length; i++) {
                        if (res.data.data[i].type === '0') {
                            this.departArray.push(res.data.data[i])
                        }
                    }
                    this.addForm.departmentType = 0
                    if (this.departArray.length === 0) {
                        for (let i = 0; i < res.data.data.length; i++) {
                            if (res.data.data[i].type === '1') {
                                this.departArray.push(res.data.data[i])
                            }
                        }
                        this.addForm.departmentType = 1
                    }
                }).catch(() => {
                    this.departArray = []
                })
            }
        },
        clearDepartment() {
            this.addForm.stakeholder = null;
            this.addForm.departmentName = null;
            this.stakeholdersArray = [];
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.$refs['addForm'].resetFields();
                this.departArray = []
                this.stakeholdersArray = []
                this.getDepartmentArray();
            } else {// 反之则关闭页面
                this.departArray = []
                this.stakeholdersArray = []
                this.closeModal(val);
            }
        }
    },
    mounted() {

    }
}
</script>
