<template>
    <Modal v-model="showForm" title="目标详情" width="680" :mask-closable="false">
        <Form class="plain-form" ref="viewForm" :model="viewForm" :rules="viewFormRule" label-colon :label-width="80">
            <div class="divider">目标信息</div>
            <Row :gutter="16">
                <Col span="12">
                    <FormItem label="周期">{{ viewForm.startDate }} ～ {{ viewForm.endDate }}</FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="目标类型">{{ viewForm.typeName }}</FormItem>
                </Col>
            </Row>
            <Row :gutter="16">
                <Col span="12">
                    <FormItem label="状态">{{ viewForm.status }}</FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="创建人">{{ viewForm.createByName }}</FormItem>
                </Col>
            </Row>
            <Row :gutter="16">
                <Col span="12">
                    <FormItem label="创建时间">{{ viewForm.createTime }}</FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="更新时间">{{ viewForm.updatedTime }}</FormItem>
                </Col>
            </Row>
            <div class="divider">目标明细</div>
            <div class="table">
                <div class="table-list">
                    <Table stripe :columns="columns" :data="dataList" :loading="loading"></Table>
                </div>
            </div>

            <div class="divider">审批记录</div>
            <FormItem label="审批历史">
                <Timeline>
                    <TimelineItem v-for="item in verifyList" :value="item.id"
                                  :key="item.id">
                        <div class="status">{{ item.status }}<span v-if="item.comment">{{ item.comment }}</span></div>
                        <div class="operator">{{ item.operatorName }} {{ item.updatedTime }}</div>
                    </TimelineItem>
                </Timeline>
            </FormItem>
        </Form>
        <div slot="footer">
            <Button type="primary" @click="closeModal(false)">确定</Button>
        </div>
    </Modal>
</template>
<script>

import {reqGetGoalById, reqGetGoalByParentId, reqGetGoalVerify} from "@/api/admin-api";

export default {
    name: "viewGoal",
    components: {},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        id: '',
    },
    data() {
        return {
            loading: false,
            showForm: this.value,
            viewForm: {},
            viewFormRule: {},
            dataList: [],
            verifyList: [],
            columns: [
                {
                    title: '目标责任人',
                    key: 'stakeholderName',
                    minWidth: 150
                },
                {
                    title: '所属组织',
                    key: 'deptName',
                    minWidth: 150
                },
                {
                    title: '金额(元)',
                    key: 'amount',
                    width: 120,
                    render: (h, params) => {
                        return h('span', params.row.amount.toFixed(2))
                    }
                },
                {
                    title: '当前达成率',
                    width: 120,
                    render: (h, params) => {
                        if (params.row.achievingRate !== null){
                            return h('span', params.row.achievingRate + "%");
                        }else {
                            return params.row.achievingRate
                        }

                    }
                },
            ],
        }
    },
    methods: {
        getGoalById() {
            reqGetGoalById({id: this.id}).then((res) => {
                if (res.data.code === 1) {
                    this.viewForm = res.data.data
                } else {
                    this.viewForm = {}
                }
            }).catch(() => {
                this.viewForm = {}
            })
            reqGetGoalByParentId({id: this.id}).then((res) => {
                if (res.data.code === 1) {
                    this.dataList = res.data.data
                } else {
                    this.dataList = []
                }
            }).catch(() => {
                this.dataList = []
            })
            reqGetGoalVerify({id: this.id}).then((res) => {
                if (res.data.code === 1) {
                    this.verifyList = res.data.data
                } else {
                    this.verifyList = []
                }
            }).catch(() => {
                this.verifyList = []
            })
        },
        closeModal(val) {
            this.imgData = null
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.$refs['viewForm'].resetFields();
                this.dataList = []
                this.verifyList = []
                this.getGoalById()
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    },
}
</script>
