<template>
    <Modal v-model="showForm" title="新增目标" width="680" :mask-closable="false">
        <Form ref="addForm" :model="addForm" :rules="addFormRule" label-colon>
            <Row :gutter="16">
                <Col span="24">
                    <FormItem class="ivu-form-item-inline" label="目标周期" prop="type" :label-width="80">
                        <Select v-model="addForm.type" placeholder="请选择" @on-change="changeType" filterable
                                clearable>
                            <Option v-for="item in objectiveTypeArray" :value="item.id" :key="item.id">{{ item.name }}
                            </Option>
                        </Select>
                    </FormItem>
                    <FormItem class="ivu-form-item-inline" prop="startDate" v-show="addForm.type === 455"
                              :label-width="10">
                        <DatePicker placeholder="请选择开始日期" v-model="addForm.startDate" type="date" format="yyyy-MM-dd"
                                    :options="weekOptions"></DatePicker>
                    </FormItem>
                    <FormItem class="ivu-form-item-inline" prop="startDate" v-show="addForm.type === 460"
                              :label-width="10">
                        <DatePicker v-model="addForm.startDate" type="month" format="yyyy-MM" placeholder="请选择开始月份"
                                    :options="futureMonthOptions"></DatePicker>
                    </FormItem>
                    <FormItem class="ivu-form-item-inline" prop="startDate" v-show="addForm.type === 470"
                              :label-width="10">
                        <DatePicker v-model="addForm.startDate" type="year" format="yyyy" placeholder="请选择开始年"
                                    :options="futureOptions"></DatePicker>
                    </FormItem>
                    <FormItem class="ivu-form-item-inline" prop="startDate" v-show="addForm.type === 465"
                              :label-width="10">
                        <DatePicker v-model="addForm.startDate" type="year" format="yyyy" placeholder="请选择年"
                                    :options="futureOptions"></DatePicker>
                    </FormItem>
                    <FormItem class="ivu-form-item-inline" prop="quarter" v-show="addForm.type === 465"
                              :label-width="10">
                        <Select v-model="addForm.quarter" placeholder="请选择季度" filterable clearable>
                            <Option value="第一季度">第一季度</Option>
                            <Option value="第二季度">第二季度</Option>
                            <Option value="第三季度">第三季度</Option>
                            <Option value="第四季度">第四季度</Option>
                        </Select>
                    </FormItem>
                </Col>
            </Row>
            <div class="filter">
                <Form ref="filter" label-colon inline>
                    <FormItem>
                        <Input v-model="filter.keywords" placeholder="请输入目标责任人" clearable/>
                    </FormItem>
                    <FormItem>
                        <Button type="primary" @click="handleSearch">搜索</Button>
                        <Button type="primary" @click="handleAddStakeholders">新增</Button>
                    </FormItem>
                </Form>
            </div>
            <Table stripe :columns="columns" :data="copyList">
                <template slot-scope="{ row, index }" slot="action">
                    <a @click="handleEditStakeholders(row, index)">编辑</a>
                    <Divider type="vertical"/>
                    <a @click="handleDelStakeholders(row, index)">删除</a>
                </template>
            </Table>
        </Form>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">确定</Button>
        </div>
        <add-stakeholders v-model="showAddStakeholders" :stakeholdersList="addForm.stakeholdersList"
                          v-on:closeAddStakeholders="closeAddStakeholders"></add-stakeholders>
        <edit-stakeholders v-model="showEditStakeholders" :data="data" :stakeholdersList="addForm.stakeholdersList"
                           v-on:closeEditStakeholders="closeEditStakeholders"></edit-stakeholders>
    </Modal>
</template>
<script>
import addStakeholders from './addStakeholders'
import {reqGetDictionaryListByCode} from "@/api/system-api";
import {reqAddGoal} from "@/api/admin-api";
import editStakeholders from './editStakeholders'

export default {
    name: "addGoal",
    components: {
        addStakeholders,
        editStakeholders
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            data: {},
            filter: {
                keywords: null,
            },
            header: {},
            loading: false,
            showForm: this.value,
            showAddStakeholders: false,
            showEditStakeholders: false,
            objectiveTypeArray: [],
            copyList: [],
            futureMonthOptions: {
                disabledDate(date) {
                    let nowTime = new Date();
                    let month = nowTime.getMonth() + 1;
                    let year = nowTime.getFullYear();
                    if (month - 1 == 0) {
                        year = year - 1;
                        month = 12;
                    }
                    return date && date.valueOf() < (new Date(`${year}-${month}`));
                }
            },
            futureOptions: {
                disabledDate(date) {
                    let nowTime = new Date();
                    let year = nowTime.getFullYear() - 1;
                    return date && date.valueOf() < (new Date(`${year}`));
                }
            },
            weekOptions: {
                disabledDate(date) {
                    return (date.getDay() !== 1 || date.valueOf() < new Date());
                }
            },
            addForm: {
                type: null,
                startDate: null,
                stakeholdersList: [],
                quarter: '',
            },
            addFormRule: {
                type: [
                    {required: true, message: '请选择目标类型', type: 'integer', trigger: 'change'}
                ],
                startDate: [{
                    required: true, trigger: 'change',
                    validator: (rule, value, callback) => {
                        if (value === null) {
                            return callback("请选择开始时间")
                        } else {
                            return callback()
                        }
                    }
                }],
            },
            columns: [
                {
                    title: '目标责任人',
                    key: 'stakeholdersName',
                    minWidth: 150,
                },
                {
                    title: '所属组织',
                    key: 'departmentName',
                    minWidth: 150,
                },
                {
                    title: '金额(元)',
                    key: 'amount',
                    width: 120,
                },
                {
                    title: '操作',
                    slot: 'action',
                    width: 120,
                    align: 'center',
                },
            ],
        }
    },
    methods: {
        closeAddStakeholders(val) {
            for (let i = 0; i < val.stakeholder.length; i++) {
                this.addForm.stakeholdersList.push({
                    amount: val.amount,
                    department: val.department,
                    departmentName: val.departmentName,
                    stakeholder: val.stakeholder[i],
                    stakeholdersName: val.stakeholdersName[i],
                    departmentType: val.departmentType
                });
            }
            this.copyList = this.addForm.stakeholdersList
        },
        closeEditStakeholders(val) {
            this.addForm.stakeholdersList.splice(val._index, 1)
            this.addForm.stakeholdersList.push({
                amount: val.amount,
                department: val.department,
                departmentName: val.departmentName,
                stakeholder: val.stakeholder,
                stakeholdersName: val.stakeholdersName,
                departmentType: val.departmentType
            });
            this.copyList = this.addForm.stakeholdersList
        },
        handleAddStakeholders() {
            this.showAddStakeholders = true
        },
        handleSubmit() {
            this.loading = true
            this.$refs['addForm'].validate((valid) => {
                if (!valid) {
                    this.loading = false;
                    return false;
                }
                if (this.addForm.stakeholdersList.length === 0) {
                    this.loading = false;
                    this.$Message.error('请选择目标责任人');
                    return false;
                }
                let nowTime = new Date();
                if (2 < nowTime.getMonth() && nowTime.getMonth() < 5 && this.addForm.quarter === '第一季度') {
                    this.loading = false;
                    this.$Message.error('请选择正确的季度');
                    return false;
                } else if (5 < nowTime.getMonth() && nowTime.getMonth() < 8 && (this.addForm.quarter === '第一季度' || this.addForm.quarter === '第二季度')) {
                    this.loading = false;
                    this.$Message.error('请选择正确的季度');
                    return false;
                } else if (8 < nowTime.getMonth() && nowTime.getMonth() < 11 && (this.addForm.quarter === '第一季度' || this.addForm.quarter === '第二季度' || this.addForm.quarter === '第三季度')) {
                    this.loading = false;
                    this.$Message.error('请选择正确的季度');
                    return false;
                }

                reqAddGoal(this.addForm).then((res) => {
                    this.loading = false;
                    if (res.data.code === 1) {
                        this.$Message.success('添加成功');
                        this.$emit('getGoal');
                        this.closeModal(false);
                    }
                }).catch(() => {
                    this.loading = false;
                    this.$Message.error('添加失败');
                })
            });
        },
        changeType(value) {
            this.addForm.startDate = null
            this.addForm.quarter = ''
        },
        handleSearch() {
            if (this.filter.keywords) {
                let myData = JSON.parse(JSON.stringify(this.addForm.stakeholdersList));
                this.copyList = myData.filter(item => (item.stakeholdersName).includes(this.filter.keywords));
            } else {
                this.copyList = JSON.parse(JSON.stringify(this.addForm.stakeholdersList));
            }
        },
        getObjectiveType() {
            reqGetDictionaryListByCode({code: "objective_type"}).then((res) => {
                this.objectiveTypeArray = res.data.data
            }).catch(() => {
                this.objectiveTypeArray = []
            })
        },
        handleDelStakeholders(row, index) {
            this.addForm.stakeholdersList.splice(index, 1);
            this.copyList = this.addForm.stakeholdersList
        },
        handleEditStakeholders(row, index) {
            this.data = row
            this.showEditStakeholders = true
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.$refs['addForm'].resetFields();
                this.copyList = []
                this.addForm.stakeholdersList = []
                this.getObjectiveType()
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    },
    mounted() {
        let token = sessionStorage.getItem('Authorization')
        this.header = {Authorization: token}
    }
}
</script>
