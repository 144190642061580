<template>
    <Modal v-model="showForm" title="编辑目标责任人" :mask-closable="false">
        <Form ref="editForm" :model="editForm" :rules="editFormRule" label-colon :label-width="110">
            <Row :gutter="16">
                <Col span="12">
                    <FormItem label="所属组织">{{ editForm.deptName }}</FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="选择目标责任人">{{ editForm.stakeholderName }}</FormItem>
                </Col>
            </Row>
            <FormItem class="plain-item" label="目标金额" prop="amount">
                <Input v-model="editForm.amount" placeholder="请输入"></Input>
            </FormItem>
        </Form>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSave">确定</Button>
        </div>
    </Modal>
</template>
<script>
export default {
    name: "editStakeholders",
    components: {},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        data: {}
    },
    data() {
        return {
            loading: false,
            showForm: this.value,
            editForm: {
                deptName: null,
                stakeholderName: null,
                amount: null,
            },
            editFormRule: {
                amount: [
                    {required: true, message: "请输入金额", trigger: "blur"},
                    {
                        pattern: /^(([1-9]{1}\d{0,7})|(0{1}))(\.\d{1,2})?$/,
                        message: "请输入合法的金额数字，最多两位小数，最大金额99999999.99",
                        trigger: "blur"
                    }
                ],
            },
        }
    },
    methods: {
        handleSave() {
            this.loading = true;
            this.$refs['editForm'].validate((valid) => {
                if (!valid) {
                    this.loading = false;
                    return false;
                }
                if (this.editForm.amount === '0'){
                    this.$Message.error("金额不能为0")
                    this.loading = false;
                    return false;
                }
                this.loading = false;
                this.$emit('closeEditStakeholders', this.editForm);
                this.closeModal(false);
            })
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.editForm = this.data;
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    },
    mounted() {}
}
</script>
